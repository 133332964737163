export interface NotesModel {
  id?: number;
  typeCode: NoteTypeCodesLiteral;
  text: string;
}

export type NoteTypeCodesLiteral = `${NoteTypeCodes}`;

export enum NoteTypeCodes {
  Opl = 'OPL',
  OplSource = 'OPLSOURCE',
  OplReq = 'OPLREQ',
  OplReqSource = 'OPLSOURCEREQ',
  HlaSearchSummaryReport = 'HLASRCHSUMRPT',
}
